@import "../../_globalColor";

.podcast-header-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 0px;
}

.podcast-main-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Keeps the column settings */
  gap: 1% 1%; /* First value is for rows, second for columns */
  justify-items: center; /* Centers items horizontally */
  align-items: start; /* Aligns items to the start of their respective rows */
  padding: 1rem; /* Adds padding around the grid for extra space */
}


.podcast-container {
  /* Additional styling for podcast container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.podcast {
  width: 100%; /* Make the podcast iframe responsive */
  max-width: 600px; /* Maximum width */
  height: auto; /* Adjust height automatically */
}

.subTitle {
  color: $subTitle;
}

.youtube-call-to-action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FF0000; /* YouTube's red color */
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.youtube-call-to-action:hover {
  background-color: #CC0000; /* Darker shade of red for hover effect */
}



.social-icons-container {
  display: flex;
  gap: 20px;
}

.social-call-to-action {
  position: relative; /* Needed for absolute positioning of the text */
  overflow: hidden; /* Ensures the text is hidden initially */
  display: inline-flex;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.social-call-to-action .icon-text {
  display: none;
  position: absolute;
  left: 100%; /* Position to the right of the icon */
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  color: black; /* or any color you prefer */
  background-color: white; /* or any background color you prefer */
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px; /* Space between icon and text */
}

.social-call-to-action:hover .icon-text {
  display: block;
}

.icon-text {
  visibility: hidden;
  position: absolute;
  left: 100%; /* Position next to the icon */
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 8px; /* Spacing between icon and text */
  white-space: nowrap;
}

.google-podcasts-call-to-action:hover .icon-text {
  visibility: visible;
}


/* Specific styles for each platform */
.youtube-call-to-action { background-color: #FF0000; }
.spotify-call-to-action { background-color: #1DB954; }
.apple-podcasts-call-to-action { background-color: #000000; }
.google-podcasts-call-to-action { background-color: #4285F4; }



@media (max-width: 768px) {
  .podcast-header-title {
    font-size: 30px;
    text-align: center;
  }
  .podcast-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  

  .podcast-main-div {
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }

  .podcast {
    max-width: 300px; /* Adjust maximum width for smaller screens */
  }

  .podcast-footer span.podcast-tag {
    background: $buttonColor;
    color: $lightBackground3;
    vertical-align: middle;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    font-size: 0.75rem;
    height: 2em;
    justify-content: center;
    white-space: nowrap;
    line-height: 1.5;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0 0.75em;
    cursor: pointer;
    transition: 0.2s ease-in;
  }
  span.podcast-tag:hover {
    background: $buttonHover;
  }
  
}
